@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  #login .container #login-row #login-column #login-box {
    margin-top: 120px;
    max-width: 320px;
    /*  height: 560px; */
    border: 1px solid #9c9c9c;
    background-color: #eaeaea;
  }
  #login .container #login-row #login-column #login-box #login-form {
    padding: 20px;
  }
  #login
    .container
    #login-row
    #login-column
    #login-box
    #login-form
    #register-link {
    margin-top: -85px;
  }
  .table-wrapper {
    max-height: 400px;
    overflow: auto;
    display: inline-block;
  }
  .table-earnings {
    background: #f3f5f6;
  }
  .control-label {
    text-align: left !important; /* !important added for priority in SO snippet. */
  }
  .right-label {
    text-align: right !important; /* !important added for priority in SO snippet. */
  }
  .navbarlinks {
    background-color: #f3f5f6;
  }
  #basic-nav-dropdown {
    color:#fff;
  }
  .Color1 {
    border-color: #1b0087;
  }
}
