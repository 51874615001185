/* .react-captcha {
    background-color: #000000;
}
 */
/* .react-captcha-icon-wrapper {
}
 */

.react-captcha-canvas{
    background-color: rgb(232, 235, 235);
    color: #fff;
}